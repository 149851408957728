import { useLazyQuery } from '@apollo/client';
import { Button, CheckButton, Input } from '@dale/react-bacan-ui';
import { GET_LEGAL_DOCUMENT } from '@dale/shared-react/lib/graphql/queries/global';
import { useState, useMemo, useEffect } from 'react';
import {
  AcceptTermsPStyled,
  AddPhoneNumberPStyled,
  PhoneContainerStyled,
  TermsContainerStyled,
  TermsPStyled,
} from './styles';
import { SendNumberProps } from './interfaces';
import { LegalDocument } from 'src/components/LegalDocument';
import { DataTreatDocumentEnum } from 'src/constants/enums';

export const SendNumber = ({
  phoneNumber,
  updatePhoneNumber,
  handleSubmit,
  clearForm
}: SendNumberProps) => {
  const [isPhoneNumberValid, setisPhoneNumberValid] = useState(true);
  const [termsChecked, setTermsChecked] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [legalDocument, setLegalDocument] = useState('');
  const [phoneKey, setPhoneKey] = useState(1)

  const [ getLegalDocument ] = useLazyQuery(GET_LEGAL_DOCUMENT, {
    variables: {
      type: DataTreatDocumentEnum.TYPE,
      person: DataTreatDocumentEnum.PERSON,
      action: DataTreatDocumentEnum.ACTION,
    },
    onCompleted(data) {
      setLegalDocument(data.getLegaldocuments.text);
    },
    onError(error) {
      console.error(`Error fetching legal document: ${error}`);
    },
  });

  useEffect(() => {
    getLegalDocument();
  }, [])

  useEffect(() => {
    if (clearForm) {
      updatePhoneNumber('');
      setPhoneKey(phoneKey+1);
      setTermsChecked(false);
    }
  }, [clearForm])

  const phoneValidation = (value: string) => {
    value.trim();
    if (!value.startsWith('3')) {
      setisPhoneNumberValid(false);
      return {
        error: true,
        valid: false,
        errorMsg: 'El número debe empezar con 3',
      };
    }
    setisPhoneNumberValid(true);
    return {
      error: false,
      valid: true,
      errorMsg: '',
    };
  };

  const buttonDisabled = useMemo(() => {
    return (
      Boolean(!termsChecked) ||
      !isPhoneNumberValid ||
      phoneNumber.length !== 10
    );
  }, [termsChecked, isPhoneNumberValid, phoneNumber]);

  return (
    <>
      <AddPhoneNumberPStyled>
        Ingresa tu número de celular y te enviaremos un mensaje de texto con los
        pasos para obtenerla!
      </AddPhoneNumberPStyled>
      <PhoneContainerStyled>
        <Input
          key={phoneKey.toString()}
          type="phone"
          value={phoneNumber}
          indicator={true}
          testid="cellphone"
          onChange={(value: string) => updatePhoneNumber(value)}
          checkValidation={value => phoneValidation(value)}
          inputProps={{
            placeholder: 'Celular',
            autoFocus: false,
          }}
        />
      </PhoneContainerStyled>
      <TermsContainerStyled>
        <CheckButton
          value=""
          isChecked={termsChecked}
          testID="checkTestID"
          onChange={() => setTermsChecked(!termsChecked)}
        />
        <AcceptTermsPStyled>
          Acepto{' '}
          <TermsPStyled onClick={() => setIsModalVisible(true)}>
            autorización para consulta y tratamiento de datos personales
          </TermsPStyled>
        </AcceptTermsPStyled>
      </TermsContainerStyled>
      <Button
        disabled={buttonDisabled}
        onClick={handleSubmit}
      >
        Envía tu solicitud
      </Button>
      {legalDocument.length >= 1 && (
        <LegalDocument
          isVisible={isModalVisible}
          text={legalDocument}
          onClose={() => setIsModalVisible(false)}
        />
      )}
    </>
  );
};
