import { NumberMaskedStyled, SmsSentPStyled } from './styles';
import { SmsSentProps } from './interfaces';

export const SmsSent = ({ phoneNumber }: SmsSentProps) => {
  const maskPhoneNumber = (phone: string): string =>
    `*** *** *${phone.slice(-3)}`;

  return (
    <SmsSentPStyled>
      ¡Listo! Revisa tu celular, enviamos un mensaje de texto al{' '}
      <NumberMaskedStyled>{maskPhoneNumber(phoneNumber)}</NumberMaskedStyled>{' '}
      con los pasos para obtener tu tarjeta
    </SmsSentPStyled>
  );
};
