import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { ActivityIndicator } from '@dale/react-bacan-ui';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { deviceIpAddress } from '../utils/getDeviceInfo';
import { v4 as uuidv4 } from 'uuid';
import { selectedEnvironment } from '../env';

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface HeaderAttributes {
  'Version-Header': number;
  ApiVersion: number;
  ChannelId: string;
  Timestamp: string;
  Application: 'BFF-GQL';
  SessionId: string | number[];
  TransactionId: string | number[];
  IpAddress?: string;
  'X-Forwarded-For'?: string;
  'Content-Type': string;
  apiVersion: number;
}

const SESSION_ID = uuidv4();

export const getDateIso8601 = (day?: string, month?: string, year?: string) => {
  if (day && month && year) {
    return `${year}-${month}-${day}`;
  }
  return format(new Date(), 'yyyy-LL-dd');
};

export const getTimeIso8601 = () => {
  return format(new Date(), 'kk:mm:ss.SSS');
};

const getTimestamp = (): string => {
  const dateStamp = getDateIso8601();
  const timeStamp = getTimeIso8601();
  return `${dateStamp}T${timeStamp}Z`;
};

const commonHeaders: HeaderAttributes = {
  'Version-Header': 1,
  ApiVersion: 1,
  ChannelId: 'DALELANDINGWEB',
  Timestamp: getTimestamp(),
  Application: 'BFF-GQL',
  SessionId: SESSION_ID,
  TransactionId: SESSION_ID,
  'Content-Type': 'application/json',
  IpAddress: '0.0.0.0',
  apiVersion: 1,
};

const basicMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = commonHeaders }) => ({
    headers,
  }));
  return forward(operation);
});

const httpLink = new HttpLink({ uri: selectedEnvironment.httpLink });

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([basicMiddleware, httpLink]),
});

export const ApolloContext = ({ children }: Props) => {
  const [getIploading, setGetIpLoading] = useState(true);

  const getIpAddress = async () => {
    try {
      const ip = await deviceIpAddress();
      if (ip) {
        commonHeaders['IpAddress'] = ip;
      }
      setGetIpLoading(false);
    } catch (e) {
      setGetIpLoading(false);
    }
  };

  useEffect(() => {
    getIpAddress();
  }, []);

  return (
    <>
      <ActivityIndicator visible={getIploading} />
      {!getIploading && (
        <ApolloProvider client={client}>{children}</ApolloProvider>
      )}
    </>
  );
};
