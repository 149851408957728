import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { environment_var } from './env';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={environment_var.reCaptchaKey}
      useEnterprise={false}
      language="es-419">
      <App />
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
);
