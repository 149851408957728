import styled from 'styled-components';

export const modalStyles = {
  paddingTop: 60,
  minWidth: 800,
};

export const LegalDocumentContainerStyled = styled.div`
  padding-left: 30;
  padding-right: 20px;
  overflow-y: scroll;
  max-height: 80vh;
`;
