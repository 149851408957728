import { Modal } from '@dale/react-bacan-ui';
import { LegalDocumentContainerStyled, modalStyles } from './styles';
import { LegalDocumentProps } from './interfaces';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

export const LegalDocument = ({
  text,
  isVisible,
  onClose,
}: LegalDocumentProps) => {
  return (
    <Modal
      isVisible={isVisible}
      onCloseModal={onClose}
      containerProps={{
        style: modalStyles,
      }}
    >
      <LegalDocumentContainerStyled>
        {parse(
          DOMPurify.sanitize(text, {
            USE_PROFILES: { html: true },
          })
        )}
      </LegalDocumentContainerStyled>
    </Modal>
  );
};
