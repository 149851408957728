import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LayoutProps } from './interfaces';
import { LayoutStyle } from './styles';
import { ActivityIndicator } from '@dale/react-bacan-ui';

export const Layout: React.FC<LayoutProps> = ({ mode, children, busy }) => {
  return (
    <>
      <ActivityIndicator visible={busy} />
      <LayoutStyle mode={mode} />
      {children}
    </>
  );
};
