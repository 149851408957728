import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLazyQuery, useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { GET_AGREEMENT_BY_PROVIDER_ID } from '@dale/shared-react/lib/graphql/queries/cards';
import { SEND_NOTIFICATION } from '@dale/shared-react/lib/graphql/mutations/notifications';
import { useState, useCallback, useEffect } from 'react';
import {
  ComponentContainerStyled,
  ContentContainerStyled,
  DownloadDalePStyled,
  MsContainerStyled,
  PassAndPayPStyled,
  SectionsContainerStyled,
  SeeYouDalePStyled,
} from './styles';
import { SendNumber } from './SendNumber';
import { SmsSent } from './SmsSent';
import MrDale from '../../assets/mr_dale.svg';
import MsDale from '../../assets/ms_dale.svg';
import DaleLogoDark from '../../assets/dale_logo_dark.svg';
import { LandingDesktopProps } from './interfaces';
import { 
  AgreementsProviderIdEnum, 
  CountryNumberIndicatorEnum, 
  NotificationCodeEnum, 
  NotificationTypeEnum, 
  SmsTypeEnum 
} from 'src/constants/enums';

export const LandingDesktop = ({utm, url}: LandingDesktopProps) => {

  const [ isSmsSent, setIsSmsSent ] = useState(false);
  const [ phoneNumber, setPhoneNumber ] = useState('');
  const [ agreementName, setAgreementName ] = useState('')
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [ clear, setClear ] = useState(false);

  const [ getAgreements ] = useLazyQuery(GET_AGREEMENT_BY_PROVIDER_ID, {
    onCompleted(data) {
      setAgreementName(data?.getAgreementByProviderIdCode?.ATTRIBUTES?.name);
    },
    onError(error) {
      console.error('Error getting agreement by provider: ', error)
    },
  })

  const [ sendNotification ] = useMutation(SEND_NOTIFICATION, {
    onCompleted() {
      setIsSmsSent(true);
    },
    onError(error) {
      setClear(true);
      console.error('Error sending notification: ', error)
    },
  })
  
  useEffect(() => {
    getAgreements({
      variables: {
        body: {
          providerId: AgreementsProviderIdEnum.NOVOPAYMENT,
          providerCode: utm
        }
      },
    })
  }, [])

  useEffect(() => {
    if (clear) setClear(false);
  }, [clear])
  

  const handleSmsSend = useCallback(async () => {
    if (!executeRecaptcha){
      console.error('Execute repatcha not available')
      return;
    }
    const recaptchatoken = await executeRecaptcha();
    sendNotification({
      variables: {
        sendNotificationArgs: {
          userId: uuidv4(),
          notificationType: NotificationTypeEnum.SMS,
          recipient: `${CountryNumberIndicatorEnum.CO}${phoneNumber}`,
          message: "",
          resend: false,
          notificationCode: NotificationCodeEnum.C054,
          SMSType: SmsTypeEnum.TRANSACTIONAL,
          deepLink: url,
          partner: agreementName,
          recaptchaToken: recaptchatoken
        }
      }
    })
  }, [executeRecaptcha, phoneNumber])

  return (
    <ComponentContainerStyled>
      <SectionsContainerStyled>
        <ContentContainerStyled>
          <img src={MrDale} alt="Hombre usando dale!" />
          <SeeYouDalePStyled>¡Nos vemos en dale!</SeeYouDalePStyled>
          <PassAndPayPStyled>Pasa, paga y recibe plata.</PassAndPayPStyled>
        </ContentContainerStyled>
      </SectionsContainerStyled>
      <SectionsContainerStyled>
        <ContentContainerStyled>
          <img src={DaleLogoDark} alt="Logo de dale!" />
          <MsContainerStyled>
            <img src={MsDale} alt="Mujer usando dale!" />
          </MsContainerStyled>
          <DownloadDalePStyled>
            Descarga dale! y solicita tu tarjeta débito
          </DownloadDalePStyled>
          {!isSmsSent ? (
            <SendNumber
              phoneNumber={phoneNumber}
              updatePhoneNumber={number => setPhoneNumber(number)}
              handleSubmit={handleSmsSend}
              clearForm={clear}
            />
          ) : (
            <SmsSent phoneNumber={phoneNumber} />
          )}
        </ContentContainerStyled>
      </SectionsContainerStyled>
    </ComponentContainerStyled>
  );
};
