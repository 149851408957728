import React, { useMemo } from 'react';
import { LandigStore, LandingDesktop } from './components';
import { ApolloContext } from './context';
import './assets/fonts/CustomFonts';
import { getDeviceType } from './utils/getDeviceInfo';
import { AllyEnum } from './constants/enums';

const DEVICE_TYPE = getDeviceType();

const App: React.FC = () => {
  const newUrl = new URL(window.location.href);
  const params = newUrl.searchParams;
  const allyUtmParam: string | undefined = params
    .get('utm_product_id')
    ?.toUpperCase()
    .trim();

  const IS_VALID_ALLY: boolean = useMemo(() => {
    switch (allyUtmParam) {
      case AllyEnum.DIM:
      case AllyEnum.MILLOS: {
        return true;
      }
      default: {
        return false;
      }
    }
  }, [allyUtmParam]);

  return (
    <ApolloContext>
      {DEVICE_TYPE === 'desktop' && IS_VALID_ALLY ? (
        <LandingDesktop 
          utm={allyUtmParam || ''} 
          url={window.location.href}
          />
      ) : (
        <LandigStore />
      )}
    </ApolloContext>
  );
};

export default App;
