import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from '@dale/react-bacan-ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 4rem;
`;

export const Body = styled.div`
  padding: 1.5rem;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 25rem;
`;

export const Title = styled.div`
  ${TYPOGRAPHY['heading-S4']}
  display: flex;
  align-items: center;
  text-align: center;
  color: ${COLORS.primary[2]};
  margin: 1.5rem 0 0.5rem;
`;

export const Subtitle = styled.div`
  padding-top: 0.5rem;
  ${TYPOGRAPHY['body-L2']}
  display: flex;
  align-items: center;
  text-align: center;
  color: ${COLORS.light};
`;

export const SubtitleBold = styled.div`
  padding-top: 0.5rem;
  ${TYPOGRAPHY['heading-S5']}
  display: flex;
  align-items: center;
  text-align: center;
  color: ${COLORS.light};
  margin-bottom: 1rem;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  max-width: 152px;
  max-height: 53px;
  padding: 8px;
  display: flex;
  align-items: center;
  background-color: ${COLORS.light};
  border-radius: 8px;
  cursor: pointer;
`;

export const TextButton = styled.div`
  ${TYPOGRAPHY['heading-S5']}
  display: flex;
  align-items: center;
  text-align: center;
  color: ${COLORS.primary[90]};
  margin-left: 0.5rem;
`;
