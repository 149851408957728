import { COLORS, TYPOGRAPHY } from '@dale/react-bacan-ui';
import styled from 'styled-components';

export const SmsSentPStyled = styled.p`
  ${TYPOGRAPHY['body-L2']};
  text-align: center;
`;

export const NumberMaskedStyled = styled.span`
  ${TYPOGRAPHY['heading-S5']};
  color: ${COLORS.quaternary.default};
`;
