import React, { useEffect } from 'react';
import { SAVE_DEEP_LINK } from '@dale/shared-react/lib/graphql/mutations/deepLinks/deepLinks';
import {
  SaveDeepLinkMutation,
  SaveDeepLinkMutationVariables,
} from '@dale/shared-react/lib/graphql/mutations/deepLinks/__generated__/deepLinks.generated';
import { useMutation } from '@apollo/client';
import {
  soDevice,
  screenSize,
  deviceIpAddress,
} from '../../utils/getDeviceInfo';
import { Layout } from '../Layout';
import Col from 'react-bootstrap/Col';
import {
  Body,
  ButtonContainer,
  Container,
  Subtitle,
  SubtitleBold,
  TextButton,
  Title,
  ContainerButtons,
} from './styles';
import DaleLogo from '../../assets/dale_logo.svg';
import ImgInfo from '../../assets/info.svg';
import PlayStore from '../../assets/play_store_icon.svg';
import AppStore from '../../assets/app_store_icon.svg';

const URL_STORE_ANDROID =
  'market://details?id=com.avalsolucionesdigitalessa.dale_app_embedded&referrer=';
const URL_STORE_IOS =
  'itms-apps://itunes.apple.com/app/id1605543375/?referrer=';

export const LandigStore = () => {
  const newUrl = new URL(window.location.href);
  const params = newUrl.searchParams;
  const paramString = params.toString();
  const paramsEncodeUri = encodeURIComponent(paramString);

  const [saveDeepLinkMutation, { loading }] = useMutation<
    SaveDeepLinkMutation,
    SaveDeepLinkMutationVariables
  >(SAVE_DEEP_LINK);

  const handleSaveDeepLink = async () => {
    const getIp = await deviceIpAddress();
    const so = soDevice();
    const screenData = screenSize();

    let url = window.location.href;
    if (url.includes('http:')) {
      url = url.replace(
        /(localhost:\d+|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}:\d+)/,
        'stores.daleapps.com',
      );
    }

    const { data } = await saveDeepLinkMutation({
      variables: {
        saveDeepLinkInput: {
          deviceOS: so,
          screenHeight: `${screenData.heightScreen}`,
          screenWidth: `${screenData.widthScreen}`,
          ip: getIp,
          params: url,
        },
      },
    });

    if (data) {
      redirectToStore({ so });
    }
  };

  const redirectToStore = ({ so }: { so: string }) => {
    setTimeout(() => {
      if (so === 'ios') {
        const urlToRedirect = `${URL_STORE_IOS}${paramsEncodeUri}`;
        window.location.href = urlToRedirect;
      }
      if (so === 'android') {
        const urlToRedirect = `${URL_STORE_ANDROID}${paramsEncodeUri}`;
        window.location.href = urlToRedirect;
      }
    }, 500);
  };

  useEffect(() => {
    handleSaveDeepLink();
  }, []);

  return (
    <Layout mode="primary" busy={loading}>
      <Col xs={12} sm={{ offset: 3, span: 6 }} lg={{ offset: 4, span: 4 }}>
        <Container>
          <Body>
            <img src={DaleLogo} style={{ width: '77px' }} alt="Logo Dale" />
            <Title>¿Listo para transformar tu experiencia financiera?</Title>
            <img src={ImgInfo} alt="Img Infor" />
            <Subtitle>
              Descarga la aplicación dale! ahora mismo y descubre una forma más
              sencilla y divertida de manejar tu dinero.
            </Subtitle>
            <SubtitleBold>Disponible en:</SubtitleBold>
            <ContainerButtons>
              <ButtonContainer
                data-testid='android_button'
                onClick={() => {
                  window.location.href = `${URL_STORE_ANDROID}${paramsEncodeUri}`;
                }}
              >
                <img src={PlayStore} alt="Logo Play Store" />
                <TextButton style={{ marginLeft: 0 }}>Play Store</TextButton>
              </ButtonContainer>
              <ButtonContainer
              data-testid='ios_button'
                onClick={() => {
                  window.location.href = `${URL_STORE_IOS}${paramsEncodeUri}`;
                }}
              >
                <img src={AppStore} alt="Logo App Store" />
                <TextButton>App Store</TextButton>
              </ButtonContainer>
            </ContainerButtons>
          </Body>
        </Container>
      </Col>
    </Layout>
  );
};
