import { COLORS, SPACING, TYPOGRAPHY } from '@dale/react-bacan-ui';
import styled from 'styled-components';

export const ComponentContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  min-width: ${SPACING['104'] * 9}px;
  background-color: ${COLORS.quaternary[30]};
`;

export const SectionsContainerStyled = styled.section`
  display: flex;
  justify-content: center;
  &:first-of-type {
    flex-grow: 1.25;
  }
  &:last-of-type {
    flex-grow: 0.75;
    border-top-left-radius: ${SPACING['40']}px;
    border-bottom-left-radius: ${SPACING['40']}px;
    background-color: ${COLORS.light};
  }
`;

export const ContentContainerStyled = styled.div`
  max-width: ${400}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SeeYouDalePStyled = styled.p`
  ${TYPOGRAPHY['heading-L3']}
  margin-top: ${SPACING['8']}px;
  margin-bottom: 0;
`;

export const PassAndPayPStyled = styled.p`
  ${TYPOGRAPHY['subheading-L5']}
`;

export const MsContainerStyled = styled.div`
  margin-top: ${SPACING['24']}px;
  margin-bottom: ${SPACING['24']}px;
`;

export const DownloadDalePStyled = styled.p`
  ${TYPOGRAPHY['heading-L5']}
  margin-bottom: ${SPACING['16']}px;
  text-align: center;
`;
