export enum AllyEnum {
  DIM = 'DIMVIRTUAL',
  MILLOS = 'MILLOSFCVRT',
}

export enum DataTreatDocumentEnum {
  TYPE = 'TDP',
  PERSON = 'PN',
  ACTION = 'ENROLLMENT',
}

export enum AgreementsProviderIdEnum {
  NOVOPAYMENT = 'novopayment'
}

export enum CountryNumberIndicatorEnum {
  CO = '+57'
}

export enum NotificationTypeEnum {
  SMS = 'sms'
}

export enum NotificationCodeEnum {
  C054 = '054'
}

export enum SmsTypeEnum {
  TRANSACTIONAL = 'transactional'
}