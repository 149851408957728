import { createGlobalStyle, css } from 'styled-components';
import backgroundImage from '../../assets/background.svg';
import mobileBackgroundImage from '../../assets/background_mobile.svg';
import { LayoutProps } from './interfaces';
import { COLORS } from '@dale/react-bacan-ui';

export const BodyStyles = {
  primary: css`
    body {
      background-color: ${COLORS.primary.default};
      background-position: top right;
      background-repeat: no-repeat;
      background-image: url(${backgroundImage});
      position: relative;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
    }

    @media (max-width: 576px) {
      body {
        background-image: url(${mobileBackgroundImage});
      }
    }
  `,
  secondary: css`
    body {
      background-color: #e5e5e5 !important;
      padding: 0 1rem;
    }
  `,
};

export const LayoutStyle = createGlobalStyle<LayoutProps>`
    ${props => BodyStyles[props.mode]}
`;
