import bowser from 'bowser';

type IPAddressType = {
  ip: string;
};

export const deviceIpAddress = async (): Promise<string> => {
  try {
    const response = await fetch('https://api.ipify.org/?format=json');
    const ipAddress: IPAddressType = await response.json();
    return ipAddress.ip;
  } catch (error) {
    console.log('Error getting IP Address: ', error);
    return '';
  }
};

export const screenSize = () => {
  const width = Math.trunc(window.screen.width * window.devicePixelRatio);
  const height = Math.trunc(window.screen.height * window.devicePixelRatio);

  return {
    widthScreen: Math.min(width, height),
    heightScreen: Math.max(width, height),
  };
};

export const soDevice = (): string => {
  const userAgentData = navigator.userAgent;

  const androidExp = /Android/i;
  const iosExp = /iPhone|iPad/i;
  const windowsExp = /Windows/i;
  const macExp = /Macintosh|Mac/i;

  if (androidExp.test(userAgentData)) return 'android';
  if (iosExp.test(userAgentData)) return 'ios';
  if (windowsExp.test(userAgentData)) return 'android';
  if (macExp.test(userAgentData)) return 'ios';
  return 'SO Not Found';
};

export const getDeviceType = (): 'mobile' | 'desktop' | null => {
  const deviceType = bowser.getParser(navigator.userAgent).getPlatformType();
  if (deviceType === 'mobile') return 'mobile';
  if (deviceType === 'desktop') return 'desktop';
  return null;
};
